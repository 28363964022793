import { useEffect, useState } from "react";
import "./App.css";
import About from "./components/about";
import Artist from "./components/artists";
import Clients from "./components/clients";
import Footer from "./components/footer";
import Header from "./components/header";
import Hero from "./components/hero";
import Mail from "./components/mail";
import MyRelease from "./components/release";
import Services from "./components/services";
import Management from "./components/team";
import Loading from "./components/loading";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  });

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <Artist />
      <Management />
      <MyRelease />
      <Services />
      <Clients />
      <Mail />
      <Footer />
    </div>
  );
}

export default App;
