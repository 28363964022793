import React, { useEffect, useState, useRef } from "react";
import SwiperSlider from "./swiper";
import { ObserverFunc } from "./Keyz/observer";
import KeyzText from "./Keyz/text";
import { releaseApi } from "../utility/constants";
import axios from "axios";

const MyRelease = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [ReleaseData, setReleaseData] = useState([]);
  const componentRef = useRef(null);

  useEffect(() => {
    ObserverFunc([{ state: setIsVisible, ref: componentRef }]);
  }, [setIsVisible, componentRef]);

  useEffect(() => {
    const releaseFetch = async () => {
      await axios.get(releaseApi).then((res) => {
        setReleaseData(res.data);
      });
    };
    releaseFetch();
  }, [setReleaseData]);

  return (
    <div
      className=" w-full flex bg-white/10  flex-col items-center py-10  relative overflow-hidden"
      id="release"
      ref={componentRef}
    >
      <div className="w-full lg:max-w-[1024px] flex flex-col items-center">
        <div>
          <div className="roboto w-full flex justify-center gap-x-2  md:text-5xl text-3xl py-8 text-white">
            <KeyzText
              text="Latest"
              state={isVisible}
              styles="font-medium"
              scaleDownBounce={true}
            />
            <KeyzText
              text="Release"
              state={isVisible}
              styles="text-red-500 font-medium"
              delay={200}
              scaleDownBounce={true}
            />
          </div>
        </div>
        <div className="lg:max-w-[1000px] w-full ">
          <SwiperSlider ReleaseData={ReleaseData} />
        </div>
      </div>
    </div>
  );
};

export default MyRelease;
